import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageHeader from '../../../components/PageHeader'
import {
    HeaderStyled,
    IconContainer,
    ISRFileUploadSectionContainer,
    ISRProcessorPageStyled
} from '../BillingThisMonth/styles'
import { FTWithRouter } from '../../../types'
import Button from '../../../components/Button2'
import UploadIcon from '../../../components/Icons/svg/UploadIcon'
import FileUploadModal from './FileUploadModal'
import { selectISRData, actions as isrDataActions } from '../../../ducks/billing/ISR/isrTableItems'
import ISRFileUploadDataTable  from './ISRFileUploadDataTable'


type FTProps =  FTWithRouter;

const ISRFileProcessorIndex = (props: FTProps) => {

    const {
        history,
    } = props;
    const handleBackNavigation = useCallback(() => {
        history.push('/billing');
    },[history]);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false)
    const dispatch = useDispatch();
    const {isrData, isrDataMeta:{loading, error}} = useSelector(selectISRData);

    useEffect(() => {
        dispatch(isrDataActions.fetchISRData());
    }, [dispatch]);
    return (
       <>
           <ISRProcessorPageStyled>
               <HeaderStyled>
                   <PageHeader Title='ISR File Processor' handleBackNavigation={handleBackNavigation} />
               </HeaderStyled>
               <ISRFileUploadSectionContainer>
                   <Button type='outlined' onClick={() => {setShowFileUploadModal(true)}} disabled={false}>
                       <IconContainer>
                           <UploadIcon height={16} width={16}/>
                       </IconContainer>
                       &nbsp;
                       Upload ISR</Button>
               </ISRFileUploadSectionContainer>
               <ISRFileUploadDataTable/>
           </ISRProcessorPageStyled>
           <FileUploadModal showDilog={showFileUploadModal} setShowDialog={setShowFileUploadModal}/>
       </>
    )
}

export default ISRFileProcessorIndex;
