import {
  STORAGE_LOGIN_TOKEN,
  STORAGE_USERNAME,
  STORAGE_USER_ID,
} from '../constants'
import { initialState as messageFlasher } from '../ducks/messageFlasher'
import { initialState as meterExport } from '../ducks/meterExport'
import { SIGNUP_REQUEST_KEY, USER_RESET_REQUEST_KEY } from '../ducks/users'

export function currentUsername() {
  return localStorage.getItem(STORAGE_USERNAME)
}

export function currentUserId() {
  return localStorage.getItem(STORAGE_USER_ID)
}

const formInitialState = {
  status: null,
  success: null,
  error: null,
}
export default {
  user: {
    forgotPasswordError: false,
    forgotPasswordLoading: false,
    loginFormEmailFieldValue: '',
    isAuthenticated: !!localStorage.getItem(STORAGE_LOGIN_TOKEN),
    username: currentUsername(),
    userId: currentUserId(),
    notice: null,
    customer: {
      id: null,
      name: null,
    },
  },
  requestStatus: {
    auth: {},
    user: {},
    customers: {},
    users: {},
    sites: {},
    customerForm: formInitialState,
    userForm: formInitialState,
    siteForm: formInitialState,
    installedStatus: formInitialState,
    historyStatus: formInitialState,
    signupForm: formInitialState,
    [SIGNUP_REQUEST_KEY]: formInitialState,
    [USER_RESET_REQUEST_KEY]: formInitialState,
  },
  upload: {
    progress: 0,
  },
  meterExport,
  messageFlasher,
}
