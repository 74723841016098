import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from '../ducks/auth'
import authzPlayground from '../ducks/authzPlayground'
import entities from './entities'
import hvacAssetReducer from '../ducks/hvacAsset'
import lightingAsBuiltsAssetReducer from '../ducks/lightingAsBuiltsAsset'
import lightingControlsTypeGuideAssetReducer from '../ducks/lightingControlsTypeGuideAsset'
import measureCodeGuideAssetReducer from '../ducks/measureCodeGuideAsset'
import messageFlasher from '../ducks/messageFlasher'
import messages from '../ducks/messages'
import meterConfig from '../ducks/meters/config'
import meterExport from '../ducks/meterExport'
import meterInstall from '../ducks/meterInstallReport'
import energyStarIntegration from '../ducks/energyStarIntegration'
import metersMostRecentMeasurementData from '../ducks/metersMostRecentMeasurementData'
import meterStatus from '../ducks/meterStatus'
import proposalOpportunitySummaryDownload from '../ducks/proposals/opportunitySummaryDownload'
import modal from '../ducks/modal'
import requestStatus from './requestStatus'
import upload from '../ducks/upload'
import user from './auth'
import { LOGIN_FAILURE } from '../constants/actionTypes'
import uploadUtilityData from '../ducks/utilityData'
import meterConfigHistory from '../ducks/configHistory'
import auditVendor from '../ducks/auditVendorValidations'
import isrProcessing from "../ducks/billing/ISR/isrProcessingTool";
import isrData from "../ducks/billing/ISR/isrTableItems";

const rootReducer =
  (history: History) =>
  (
    state: Record<string, any>,
    action: {
      type: string
    },
  ) => {
    const logoutState = {
      user: {
        notice: 'logout',
      },
    }
    const { user: userLogoutState } = logoutState
    const { user: userState = {} } = state
    const { loginFormEmailFieldValue = '' } = userState
    const noAccessState = {
      user: { ...userLogoutState, loginFormEmailFieldValue },
    }
    let newState

    if (action.type === 'LOGOUT_REQUEST') {
      newState = logoutState
    } else if (action.type === LOGIN_FAILURE) {
      newState = noAccessState
    } else {
      newState = state
    }

    return combineReducers({
      auth,
      authzPlayground,
      entities,
      hvacAsset: hvacAssetReducer,
      lightingAsBuiltsAsset: lightingAsBuiltsAssetReducer,
      lightingControlsTypeGuideAsset: lightingControlsTypeGuideAssetReducer,
      measureCodeGuideAsset: measureCodeGuideAssetReducer,
      messageFlasher,
      messages,
      meterConfig,
      meterExport,
      meterInstall,
      energyStarIntegration,
      metersMostRecentMeasurementData,
      meterStatus,
      modal,
      proposalOpportunitySummaryDownload,
      requestStatus,
      router: connectRouter(history),
      upload,
      uploadUtilityData,
      user,
      meterConfigHistory,
      auditVendor,
      isrProcessing,
      isrData,
    })(newState, action)
  }

export default rootReducer
