/* eslint-disable guard-for-in */
import { Parser } from '@json2csv/plainjs'
import Big from 'big.js'
import getSymbolFromCurrency from 'currency-symbol-map'
import FileSaver from 'file-saver'
import moment from 'moment'
import type { ComponentType } from 'react'
import React, { useContext, useRef } from 'react'
import { TbCsv, TbPdf } from 'react-icons/tb'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import styled, { css } from 'styled-components'
import { parseUrlParams, queryStringify } from '../../../api/utils'
import FeatureValidator from '../../../authorization/components/FeatureValidator'
import AuthorizedFeatures from '../../../authorization/features'
import Breadcrumbs2 from '../../../components/Breadcrumbs2'
import ColorIndicator from '../../../components/ColorIndicator'
import DownloadVendorFile from '../../../components/DownloadVendorFile'
import DropdownMenuNew from '../../../components/DropdownMenuNew'
import ArrowBackIcon from '../../../components/Icons/ArrowBackIcon'
import AutorenewIcon from '../../../components/Icons/AutorenewIcon'
import CheckCircleOutlineIcon from '../../../components/Icons/CheckCircleOutlineIcon'
import Copy from '../../../components/Icons/Copy'
import EditNoteIcon from '../../../components/Icons/EditNoteIcon'
import HourglassEmptyIcon from '../../../components/Icons/HourglassEmptyIcon'
import Inventory2OutlinedIcon from '../../../components/Icons/Inventory2OutlinedIcon'
import KeyboardDoubleArrowDownIcon from '../../../components/Icons/KeyboardDoubleArrowDownIcon'
import KeyboardDoubleArrowUpIcon from '../../../components/Icons/KeyboardDoubleArrowUpIcon'
import Layers from '../../../components/Icons/Layers'
import LockIconOutlined from '../../../components/Icons/LockIconOutlined'
import LockOpenIcon from '../../../components/Icons/LockOpenIcon'
import SeekMarginIcon from '../../../components/Icons/svg/SeekMargin'
import IonIcon, { IonIconStyles } from '../../../components/IonIcon'
import AddScenarioDropdown from '../../../components/ProposalsEngine/AddScenarioDropdown'
import ConfirmModal, {
  ConfirmBodyContentStyled,
  ConfirmBodyScenarioNameStyled,
  ConfirmBodyTitleStyled,
  ConfirmModalSubmitButtonStyled,
} from '../../../components/ProposalsEngine/ConfirmModal'
import ModalAlerts from '../../../components/ProposalsEngine/ModalAlerts'
import type { FTModalLockUnlock } from '../../../components/ProposalsEngine/ModalLockUnlock'
import '../../../components/ProposalsEngine/ModalScenario'
import type { FTShowModalScenario } from '../../../components/ProposalsEngine/ModalScenario'
import '../../../components/ProposalsEngine/ModalSeekMargin'
import type { FTModalSeekMargin } from '../../../components/ProposalsEngine/ModalSeekMargin'
import TableStyledLink from '../../../components/ProposalsEngine/TableStyledLink'
import RedaptiveReactTable7, {
  ReactTableStyled,
  ReactTableWrapperStyles,
} from '../../../components/RedaptiveReactTable7'
import Spinner from '../../../components/Spinner'
import { selectors as authSelectors } from '../../../ducks/auth'
import type { FTMessageInput } from '../../../ducks/messages'
import { actions as messagesActions } from '../../../ducks/messages'
import { actions as modalActions } from '../../../ducks/modal'
import type {
  FTFetchProposalCustomerGlobalInputsAction,
  FTProposalCustomerGlobalInputs,
} from '../../../ducks/proposals/customerGlobalInputs'
import {
  actions as customerGlobalInputsActions,
  selectProposalCustomerGlobalInputsEntity,
} from '../../../ducks/proposals/customerGlobalInputs'
import type {
  FTFetchProposalCustomerSummariesAction,
  FTProposalCustomerSummary,
} from '../../../ducks/proposals/customerSummaries'
import {
  actions as customerSummariesActions,
  selectProposalCustomerSummariesEntity,
} from '../../../ducks/proposals/customerSummaries'
import type { FTImportedScenarioPayload } from '../../../ducks/proposals/importedScenario'
import {
  actions as importedScenarioActions,
  selectProposalImportedScenario,
} from '../../../ducks/proposals/importedScenario'
import type {
  FTFetchProposalOpportunityFieldMappingAction,
  FTFetchProposalOpportunitySummariesAction,
  FTFetchProposalSalesforceStatus,
  FTFetchProposalSalesforceStatusAction,
  FTProposalOpportunitySummariesMetaState,
  FTProposalOpportunitySummary,
  FTUpdateOpportunityLockUnlockStatus,
  FTUpdateProposalOpportunityFieldMappingAction,
  FTUpdateProposalOpportunityStatusAction,
} from '../../../ducks/proposals/opportunitySummaries'
import {
  opportunityStatuses,
  actions as opportunitySummariesActions,
  selectProposalOpportunitySummariesEntity,
} from '../../../ducks/proposals/opportunitySummaries'
import type {
  FTProposalOpportunitySummaryDownloadAction,
  FTProposalOpportunitySummaryDownloadMetaState,
} from '../../../ducks/proposals/opportunitySummaryDownload'
import {
  actions as opportunitySummaryDownloadActions,
  selectProposalOpportunitySummaryDownloadMeta,
} from '../../../ducks/proposals/opportunitySummaryDownload'
import type {
  FTFetchProposalScenariosAction,
  FTProposalScenario,
  FTProposalScenarioResponse,
  FTUpdateProposalScenarioAction,
  FTUpdateProposalScenarioStatusAction,
} from '../../../ducks/proposals/scenarios'
import {
  formatScenarioFieldValueBoolean,
  formatScenarioFieldValueCurrency,
  formatScenarioFieldValueEnum,
  formatScenarioFieldValuePercentage,
  getScenarioCalculations,
  actions as scenarioActions,
  scenarioFieldInfo,
  selectProposalScenarioEntity,
} from '../../../ducks/proposals/scenarios'
import type {
  FTFetchProposalSiteAction,
  FTProposalSite,
} from '../../../ducks/proposals/sites'
import {
  selectProposalSiteEntity,
  actions as siteActions,
} from '../../../ducks/proposals/sites'
import {
  DATE_FORMAT_DATA_API_RESPONSE,
  DATE_FORMAT_TIMESTAMP,
} from '../../../ducks/utils'
import type { FTRouterLocation, FTWithRouter } from '../../../types'
import {
  getCurrencyFormat,
  getNameFromEMail,
  getNumberFormatMaxFractionDigits2,
  naturallySortEmptyLastCaseInsensitive,
  zIndices,
} from '../../../utils'
import {
  ADD_SCENARIO_STATES,
  UPDATE_SALESFORCE_STATES,
} from '../../../utils/constants'
import proposalFieldMappings, {
  proposalReviewCSVMappings,
  proposalReviewMappings,
} from '../../../utils/proposalFieldMappings'
import { colors } from '../../../utils/themes'
import FileContext from '../../FileContext'
import { SpinnerStyles } from './CustomerListingPage'
import './custom.css'
import { salesTaxWarningValue } from './utils'
import UpdateSalesforceAlerts from '../../../components/ProposalsEngine/ModalAlerts/UpdateSalesforceAlerts'
import { useRollbarContext } from '@rollbar/react'

export const addScenarioAlerts = {
  loading: {
    type: 'loading',
    text: 'Please wait a few seconds for it to complete.',
    heading: 'The file is being uploaded...',
  },
  warning: {
    type: 'warning',
    text: 'Do you still want to proceed by making updates and corrections in the form?',
    heading: 'Some fields could not be mapped.',
  },
  success: {
    type: 'success',
    heading: 'File Uploaded successfully',
  },
  danger: {
    type: 'danger',
    text: 'File format is not .xlsm or .xlsx',
    heading: 'File Upload Failed',
  },
  fileNameLength: {
    type: 'danger',
    text: 'The name of the file is too long. Please update it to be < than 200 characters.',
    heading: 'File name length check',
  },
  fieldMappingError: {
    type: 'danger',
    heading: 'File Upload Fail - Incorrect data format',
  },
}
export const updateSalesforceAlerts = {
  success: {
    type: 'success',
    heading: 'Salesforce updated successfully',
  },
  danger: {
    type: 'danger',
    heading: 'Salesforce Update Failed - ',
  },
}
type FTProps = {
  actions: {
    hideModal: () => null
    fetchProposalCustomerSummaries: (
      params: FTFetchProposalCustomerSummariesAction | null | undefined,
    ) => null
    fetchProposalCustomerGlobalInputs: (
      params: FTFetchProposalCustomerGlobalInputsAction | null | undefined,
    ) => null
    fetchProposalOpportunitySummaries: (
      params: FTFetchProposalOpportunitySummariesAction,
    ) => null
    fetchProposalSite: (props: FTFetchProposalSiteAction) => null
    fetchProposalScenarios: (params: FTFetchProposalScenariosAction) => null
    downloadProposalOpportunitySummary: (
      props: FTProposalOpportunitySummaryDownloadAction,
    ) => null
    showModalScenario: (params: FTShowModalScenario) => null
    showModalLockUnlock: (params: FTModalLockUnlock) => null
    showGoalSeekModal: (params: FTModalSeekMargin) => null
    updateProposalScenario: (params: FTUpdateProposalScenarioAction) => null
    updateProposalScenarioStatus: (
      props: FTUpdateProposalScenarioStatusAction,
    ) => null
    updateProposalOpportunityStatus: (
      props: FTUpdateProposalOpportunityStatusAction,
    ) => null
    fetchProposalOpportunityFieldMappings: (
      props: FTFetchProposalOpportunityFieldMappingAction,
    ) => null
    updateProposalOpportunityFieldMappings: (
      props: FTUpdateProposalOpportunityFieldMappingAction,
    ) => null
    fetchSalesforceScenarioStatus: (
      props: FTFetchProposalSalesforceStatusAction,
    ) => null
    updateOpportunityLockUnlockStatus: (
      props: FTUpdateOpportunityLockUnlockStatus,
    ) => null
    hideMessage: (value: string) => null
    showMessage: (props: FTMessageInput) => null
    saveImportedScenarioDetails: (props: FTImportedScenarioPayload) => null
    requestImportedScenarioFields<T>(): (props: T) => null
  }
  authGroups: Array<string>
  customerGlobalInputs: FTProposalCustomerGlobalInputs
  customerSummariesById: Record<string, FTProposalCustomerSummary>
  customerSummariesLoading: boolean
  opportunityStatusUpdateMeta: FTProposalOpportunitySummariesMetaState
  proposalSalesforceStatus: FTFetchProposalSalesforceStatus
  opportunitySummaries: Array<FTProposalOpportunitySummary>
  opportunitySummaryDownloadMeta: FTProposalOpportunitySummaryDownloadMetaState
  scenarios: Array<FTProposalScenario>
  scenariosById: Record<string, FTProposalScenario>
  scenariosLoading: boolean
  sites: Array<FTProposalSite>
  opportunitySummariesLoading: boolean
} & FTWithRouter &
  FTRouterLocation
const ArchivedRowVisibleStyles = css`
  td {
    background-color: #f5f5f5;
  }
`
const ArchivedRowHiddenStyles = css`
  & ~ .row-status-archived {
    display: none;
  }
`
const tableMarginBottom = 130
const OpportunityAnalysisPageStyles: ComponentType<{
  archivedVisible: boolean
}> = styled.div`
  font-family: 'Public Sans', sans-serif;
  margin-top: 36px;

  * {
    box-sizing: border-box;
  }

  a {
    color: ${colors.blue2};
  }

  .row-status-archived {
    ${({ archivedVisible }) =>
      archivedVisible ? ArchivedRowVisibleStyles : ArchivedRowHiddenStyles};
  }

  .row-status-scoping_approved,
  .old-scenario {
    ${ArchivedRowVisibleStyles};
  }

  ${ReactTableStyled} {
    margin-bottom: ${tableMarginBottom}px;
  }
`
const ArchiveConfirmModalWrapperStyled = styled.div`
  ${ConfirmModalSubmitButtonStyled} {
    border: 1px solid ${colors.red};
    background-color: ${colors.red};
  }
`
const BreadcrumbsStyles = styled.div`
  margin-bottom: 24px;
`
const HeaderWrapperStyles = styled.div`
  align-items: center;
  background-color: #fafafa;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  display: flex;
  height: 60px;
  line-height: 1;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 32px;
  position: relative;
`
const TitleWrapperStyled = styled.div`
  align-items: center;
  display: flex;

  ${ArrowBackIcon} {
    cursor: pointer;
    font-size: 18px;
  }
`
const HeaderActionStyled = styled.div`
  align-items: center;
  color: ${colors.blue3};
  cursor: pointer;
  position: relative;
`
const HeaderActionsStyled = styled.div`
  align-items: center;
  color: ${colors.blue3};
  display: flex;
  font-size: 12px;

  ${HeaderActionStyled} + ${HeaderActionStyled} {
    margin-left: 32px;
  }
`
const HeaderActionIconStyles = css`
  margin-right: 6px;
`
const CopyIconStyled = styled(Copy)`
  ${HeaderActionIconStyles};
  width: 100%;
  position: absolute;
`
const AutorenewIconIconStyled = styled(AutorenewIcon)`
  ${HeaderActionIconStyles};
`
const LockOpenIconStyled = styled(LockOpenIcon)`
  ${HeaderActionIconStyles};
`
const LockIconOutlinedStyled = styled(LockIconOutlined)`
  ${HeaderActionIconStyles};
`
const HeaderActionBodyLoadingStyles = css`
  opacity: 0.5;
`
const HeaderActionBodyStyled: ComponentType<{
  $loading: boolean
}> = styled.div`
  align-items: center;
  display: flex;
  ${({ $loading }) => $loading && HeaderActionBodyLoadingStyles};
  span {
    margin-left: 32px;
  }
`
const DownloadSummaryActionSpinnerStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
`
const TitleStyles = styled.span`
  display: inline-block;
  padding-left: 6px;
  font-size: 18px;
  font-weight: 300;
  padding-right: 6px;
`
const OpportunityStatusStatusIndicator = styled(ColorIndicator)`
  margin-right: 5px;
`
const OpportunityStatusWrapperStyled = styled.div`
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 8px;
  display: flex;
  font-size: 12px;
  margin-left: 16px;
  padding: 10px 16px;
`

const OpportunityStatus = ({ status }: { status: string }) =>
  (status &&
    opportunityStatuses[status] &&
    opportunityStatuses[status].displayName && (
      <OpportunityStatusWrapperStyled>
        <OpportunityStatusStatusIndicator
          color={opportunityStatuses[status].color}
        />
        {opportunityStatuses[status].displayName}
      </OpportunityStatusWrapperStyled>
    )) ||
  ''

const defaultSort = [
  {
    id: 'name',
  },
]
const alwaysLockedColumns = ['name']
const ActionsCellWrapperStyled = styled.div`
  position: relative;
`
const ActionsIconStyled = styled.div`
  cursor: pointer;
  height: 20px;
  width: 20px;

  ${IonIconStyles} {
    & > span {
      left: 8px;
      position: absolute;
      top: -7px;
    }
  }
`
const ActionsPopupStyled: ComponentType<{
  open: boolean
}> = styled.div`
  background: #fff;
  box-shadow: 0 0 10px 2px rgba(130, 130, 130, 0.25);
  border-radius: 4px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  left: -220px;
  padding: 16px;
  position: absolute;
  top: 0;
  width: 214px;
  z-index: ${zIndices.RedaptiveReactTableActionsPopup};
`
const ActionStyled = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`
const ArchiveActionStyled = styled(ActionStyled)`
  color: #b71111;
`
const ActionIconWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  margin-right: 5px;
`
const TableWrapperStyled = styled.div`
  position: relative;

  ${ReactTableWrapperStyles} {
    overflow-x: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`
const archiveToggleHeight = 43
const ArchiveToggleStyled: ComponentType<{
  archivedVisible: boolean
}> = styled.div`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    #ffffff 100%
  );
  bottom: ${({ archivedVisible }) =>
    archivedVisible ?
      `${tableMarginBottom - archiveToggleHeight}px`
    : `${tableMarginBottom}px`};
  color: ${colors.blue2};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: ${archiveToggleHeight}px;
  justify-content: center;
  letter-spacing: 0.02em;
  position: absolute;
  width: 100%;
`
const ArchiveToggleIconStyled = styled.div`
  margin-right: 6px;
  position: relative;
  top: 2px;
`
const currencySet = new Set([
  'preTaxContractValue',
  'totalContractValueWithSalesTax',
  'netMarginInDollars',
  'partnerFee',
  'referralCost',
  'contingencyCost',
  'upFrontMeasurementAndVerification',
  'finalRebateAmount',
  'annualEnergySavingsInDollars',
  'annualMaintenanceSavings',
  'costReduction',
  'upFrontPayment',
  'utilityRate',
  'contractRate',
])
const percentageSet = new Set([
  'energyRetainedSavingsInPercentage',
  'maintenanceRetainedSavingsInPercentage',
  'netMarginInPercentage',
  'internalRateOfReturn',
  'grossMarginInPercentage',
  'partnerFeeInPercentage',
  'referralFeeInPercentage',
  'contingencyInPercentage',
  'rebateHcInPercentage',
])
const DownloadDropDownWrapper = styled.div`
  margin-left: 20px;
  .title {
    font-weight: 400;
  }
`

export const cellEnum = ({ value }) =>
  formatScenarioFieldValueEnum({
    value,
  })

export const cellBoolean = ({ value }) =>
  formatScenarioFieldValueBoolean({
    value,
  })

const formatDateStringForView = ({ value }) => {
  if (value)
    return moment(value, DATE_FORMAT_DATA_API_RESPONSE).format(
      DATE_FORMAT_TIMESTAMP,
    )
  return '-'
}

const convertEmailToNameForView = ({ value }) => {
  if (value) return getNameFromEMail(value)
  return '-'
}

export const sortScenarios = (
  scenarioA: FTProposalScenario,
  scenarioB: FTProposalScenario,
) => {
  const { name: nameA, status: statusA } = scenarioA
  const { name: nameB, status: statusB } = scenarioB

  if (statusA !== statusB) {
    return statusA === 'ARCHIVED' ? 1 : -1
  }

  return naturallySortEmptyLastCaseInsensitive(nameA, nameB)
}

export const reactTableSortArchivedScenariosLast = (
  rowA: Record<string, any>,
  rowB: Record<string, any>,
  columnId: string,
  desc: boolean,
) => {
  const valueA = rowA.values[columnId]
  const valueB = rowB.values[columnId]
  const { status: valueAStatus } = rowA.original
  const { status: valueBStatus } = rowB.original

  if (
    valueAStatus !== valueBStatus &&
    (valueAStatus === 'ARCHIVED' || valueBStatus === 'ARCHIVED')
  ) {
    const ascendingValue = valueAStatus === 'ARCHIVED' ? 1 : -1
    return desc ? -ascendingValue : ascendingValue
  }

  return naturallySortEmptyLastCaseInsensitive(valueA, valueB, desc)
}

export const proposalModalContent = {
  NEW_CONTRACT: {
    header: 'A contract for this opportunity exists in Salesforce',
    logMessage: '',
    body: (
      <p>
        The existing contract has been approved. It will be inactivated and a
        new contract will be created. Are you sure you want to continue?
        <br />
        Please contact a senior member of the Proposal Operations or Tech team
        if you have any questions.
      </p>
    ),
  },
  UPDATE: {
    header:
      'A contract for this opportunity has already been created in Salesforce.',
    logMessage: '',
    body: (
      <p>
        The contract will be updated. Are you sure you want to continue?
        <br />
        Please contact a senior member of the Proposal Operations or Tech team
        if you have any questions.
      </p>
    ),
  },
  REJECT_AND_UPDATE: {
    header: 'Are you sure you want to continue?',
    logMessage: (
      <p>
        A contract for this opportunity has already been created in Salesforce
        and is in approval queue.
        <br />
        <br />
        The contract will be rejected before being updated. If there are no
        changes made, this action will only reject the contract.
      </p>
    ),
    body: (
      <p>
        Please contact a senior member of the Proposal Operations or Tech team
        if you have any questions.
      </p>
    ),
  },
  DEFAULT: {
    header:
      'You are creating a new contract for this opportunity in Salesforce.',
    logMessage: '',
    body: (
      <p>
        Are you sure you want to continue?
        <br />
        Please contact a senior member of the Proposal Operations or Tech team
        if you have any questions.
      </p>
    ),
  },
}

const OpportunityAnalysisPage = (props: FTProps) => {
  const {
    actions,
    authGroups,
    customerGlobalInputs,
    customerSummariesById,
    customerSummariesLoading,
    history,
    location,
    match: {
      params: { salesforceCustomerId, opportunityId },
      url,
    },
    opportunityStatusUpdateMeta,
    proposalSalesforceStatusMeta,
    opportunitySummaries,
    fieldMappings,
    fieldMappingsMeta,
    opportunitySummaryDownloadMeta,
    proposalSalesforceStatus,
    scenarios,
    scenariosById,
    scenariosLoading,
    sites,
    permissions,
    importedScenarioData,
    opportunitySummariesLoading,
  } = props

  const { setVendorIngestedFile } = useContext(FileContext)
  const isProposalOperationsUser = React.useMemo(
    () =>
      authGroups.some((authGroup) =>
        ['proposal-operations-admin', 'proposal-operations'].includes(
          authGroup,
        ),
      ),
    [authGroups],
  )
  const isProposalOperationsAdmin = React.useMemo(
    () =>
      authGroups.some((authGroup) =>
        ['proposal-operations-admin'].includes(authGroup),
      ),
    [authGroups],
  )
  const opportunitySummary = React.useMemo(
    () =>
      opportunitySummaries.find(
        (opportunity) => opportunity.externalId === opportunityId,
      ),
    [opportunitySummaries, opportunityId],
  )
  const { currencyCode = 'USD' } = opportunitySummary || {}
  const { locale = 'en-US' } = customerGlobalInputs || {}
  const cellCurrency = React.useCallback(
    ({ value, column: { id: fieldName } }) =>
      formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value,
        precision: scenarioFieldInfo[fieldName].precision,
      }),
    [currencyCode, locale],
  )
  const cellNumber2DigitsPercentage = React.useCallback(
    ({ value }) =>
      formatScenarioFieldValuePercentage({
        locale,
        value,
      }),
    [locale],
  )
  const customerSummary = customerSummariesById[salesforceCustomerId] || {}
  const { name: customerName = '' } = customerSummary || {}
  const { opportunityStatus, redaptiveOpportunityId, isLocked } =
    opportunitySummary || {}
  const queryParams = React.useMemo(() => parseUrlParams(location), [location])
  const site = sites[0]
  const isOpportunityInInstallation =
    opportunitySummary?.stageName === 'Installing'
  const isOpportunityInBillingMonitoring =
    opportunitySummary?.stageName === 'Billing & Monitoring'
  const numberFormatMaxFractionDigits2 =
    getNumberFormatMaxFractionDigits2('en-US')
  const currencyFormat = getCurrencyFormat(locale, currencyCode, 2)
  const [actionsPopupId, setActionsPopupId] = React.useState('')
  const [salesforcePopupId, setSalesforcePopupId] = React.useState(false)
  const [salesforceAlertPopup, setSalesforceAlertPopup] = React.useState(false)
  const [modalScenarioOpen, setModalScenarioOpen] = React.useState(false)
  const [modalLockUnlockOpen, setModalLockUnlockOpen] = React.useState(false)
  const [modalSeekMarginIsOpen, setModalSeekMarginIsOpen] =
    React.useState(false)
  const [archivedVisible, setArchivedVisible] = React.useState(false)
  const [cloneConfirmModalId, setCloneConfirmModalId] = React.useState('')
  const [archiveConfirmModalId, setArchiveConfirmModalId] = React.useState('')
  const [updateSalesforceId, setUpdateSalesforceId] = React.useState('')
  const [scenarioActivating, setScenarioActivating] = React.useState(false)
  const [addScenarioAlertStatus, setAddScenarioAlertStatus] = React.useState<
    string | null
  >(null)
  const [updateSalesforceAlertsStatus, setupdateSalesforceAlertsStatus] =
    React.useState<string | null>(null)
  const [uploadedFileName, setUploadedFileName] = React.useState<string | null>(
    null,
  )
  const showColumnSettings = React.useMemo(() =>
    AuthorizedFeatures.editProposalFieldMappings.anyMatchesWithPermissions(
      permissions,
    ),
  )
  const [updatedFieldMappings, setUpdatedFieldMappings] = React.useState(
    fieldMappings.length ? fieldMappings : proposalFieldMappings,
  )
  const isMountedProposalSite = useRef(false)
  const isMountedProposalOpportunity = useRef(false)
  useRollbarContext('Opportunity Analysis Page')
  React.useEffect(() => {
    if (Object.keys(importedScenarioData || {}).length) {
      const { isPrimaryValidationsPassed } = importedScenarioData || {}

      if (importedScenarioData.fileParsingError) {
        setAddScenarioAlertStatus(ADD_SCENARIO_STATES.FIELD_MAPPING_ERROR)
      } else if (isPrimaryValidationsPassed) {
        setAddScenarioAlertStatus(ADD_SCENARIO_STATES.SUCCESS)
      } else {
        setAddScenarioAlertStatus(ADD_SCENARIO_STATES.WARNING)
      }
    } else {
      setAddScenarioAlertStatus(null)
    }
  }, [importedScenarioData])
  React.useEffect(() => {
    if (scenarioActivating && !scenariosLoading) {
      setScenarioActivating(false)
      actions.fetchProposalScenarios({
        opportunityId,
      })
    }
  }, [scenarioActivating, scenariosLoading])
  React.useEffect(() => {
    actions.fetchProposalOpportunityFieldMappings({
      opportunityId,
    })
  }, [opportunityId])
  const hideScenarioModal = React.useCallback(() => {
    const { editMode, scenario, ...newQueryParams } = queryParams
    actions.hideModal()
    history.push(`${url}?${queryStringify(newQueryParams)}`)
  }, [queryParams, actions])
  const hideLockUnlockModal = React.useCallback(() => {
    const { showLockUnlockModal, ...newQueryParams } = queryParams
    actions.hideModal()
    history.push(`${url}?${queryStringify(newQueryParams)}`)
  }, [queryParams, actions])

  const clearVendorIngestionData = () => {
    setVendorIngestedFile(null)
    actions.saveImportedScenarioDetails({})
  }

  React.useEffect(() => {
    clearVendorIngestionData()
  }, [])
  const hideSeekMarginModal = React.useCallback(() => {
    const { seekMarginScenarioId, ...newQueryParams } = queryParams
    actions.hideModal()
    history.push(`${url}?${queryStringify(newQueryParams)}`)
  }, [queryParams, actions])
  const onSubmitScenarioEdit = React.useCallback(
    (values: FTUpdateProposalScenarioAction) => {
      actions.updateProposalScenario(values)
    },
    [],
  )
  const onLockUnlockSubmit = React.useCallback(() => {
    actions.updateOpportunityLockUnlockStatus({
      opportunityId,
      updatedStatus: !isLocked,
    })
    actions.fetchProposalOpportunitySummaries({
      salesforceCustomerId,
    })
    actions.fetchProposalScenarios({
      opportunityId,
    })
  }, [redaptiveOpportunityId, isLocked])
  const onSubmitSeekMargin = React.useCallback((values) => {
    actions.updateProposalScenario(values)
  }, [])
  React.useEffect(() => {
    if (queryParams.scenario && !modalScenarioOpen) {
      const scenario = scenariosById[queryParams.scenario]

      if (customerGlobalInputs && scenario && site && opportunitySummary) {
        const { editMode } = queryParams
        setModalScenarioOpen(true)
        actions.showModalScenario({
          closeModal: hideScenarioModal,
          customerGlobalInputs,
          editMode: editMode && scenario.status !== 'APPROVED',
          onSubmit: onSubmitScenarioEdit,
          opportunity: opportunitySummary,
          queryParams,
          scenarioId: queryParams.scenario,
          site,
          url,
        })
      }
    } else if (!queryParams.scenario && modalScenarioOpen) {
      setModalScenarioOpen(false)
    }
  }, [
    customerGlobalInputs,
    location,
    opportunitySummary,
    queryParams,
    scenarios,
    site,
  ])
  React.useEffect(() => {
    if (queryParams.showLockUnlockModal && !modalLockUnlockOpen) {
      if (opportunitySummary) {
        setModalLockUnlockOpen(true)
        actions.showModalLockUnlock({
          closeModal: hideLockUnlockModal,
          onSubmit: onLockUnlockSubmit,
          isLocked,
        })
      }
    } else if (!queryParams.showLockUnlockModal && modalLockUnlockOpen) {
      setModalLockUnlockOpen(false)
    }
  }, [
    customerGlobalInputs,
    location,
    opportunitySummary,
    queryParams,
    scenarios,
    site,
  ])
  React.useEffect(() => {
    if (queryParams.seekMarginScenarioId && !modalSeekMarginIsOpen) {
      const scenario = scenariosById[queryParams.seekMarginScenarioId]

      if (scenario && site && opportunitySummary) {
        setModalSeekMarginIsOpen(true)
        actions.showGoalSeekModal({
          closeModal: hideSeekMarginModal,
          scenarioId: queryParams.seekMarginScenarioId,
          opportunity: opportunitySummary,
          onSubmit: onSubmitSeekMargin,
          site,
          scenario,
        })
      }
    } else if (!queryParams.seekMarginScenarioId && modalSeekMarginIsOpen) {
      setModalSeekMarginIsOpen(false)
    }
  }, [
    customerGlobalInputs,
    location,
    opportunitySummary,
    queryParams,
    scenarios,
    site,
  ])
  React.useEffect(() => {
    if (!Object.keys(customerSummariesById).length) {
      actions.fetchProposalCustomerSummaries()
    }

    actions.fetchProposalScenarios({
      opportunityId,
    })
    if (!opportunitySummaries.length) {
      actions.fetchProposalOpportunitySummaries({
        salesforceCustomerId,
        stageNames: ['SCOPING', 'INSTALLING', 'BILLING_AND_MONITORING'],
      })
    }
  }, [])
  React.useEffect(() => {
    if (isMountedProposalSite.current) {
      if (!scenariosLoading && scenarios.length) {
        actions.fetchProposalSite({
          id: scenarios[0].salesforceSiteId,
        })
      }
    } else {
      isMountedProposalSite.current = true
    }
  }, [scenariosLoading])
  React.useEffect(() => {
    if (isMountedProposalOpportunity.current) {
      if (
        ((!scenariosLoading && scenarios) ||
          (!proposalSalesforceStatusMeta.loading &&
            !opportunityStatusUpdateMeta.loading) ||
          !scenarios.length) &&
        opportunitySummaries.length
      ) {
        actions.fetchProposalOpportunitySummaries({
          salesforceCustomerId,
          stageNames: ['SCOPING', 'INSTALLING', 'BILLING_AND_MONITORING'],
        })
      }
    } else {
      isMountedProposalOpportunity.current = true
    }
  }, [scenarios, proposalSalesforceStatus, opportunityStatusUpdateMeta.loading])
  React.useEffect(() => {
    if (fieldMappings.length) {
      setUpdatedFieldMappings(fieldMappings)
    } else {
      setUpdatedFieldMappings(proposalFieldMappings)
    }
  }, [fieldMappings])
  React.useEffect(() => {
    if (
      !scenariosLoading &&
      scenarios &&
      !opportunityStatusUpdateMeta.loading &&
      salesforceAlertPopup
    ) {
      if (opportunityStatusUpdateMeta.success) {
        setupdateSalesforceAlertsStatus(UPDATE_SALESFORCE_STATES.SUCCESS)
      }
    }
  }, [opportunityStatusUpdateMeta])
  const navigateBack = React.useCallback(
    () =>
      history.push(
        `/proposal-operations/proposals-engine/${salesforceCustomerId}`,
      ),
    [],
  )
  const breadcrumbs = React.useMemo(
    () => [
      {
        href: '/proposal-operations/proposals-engine',
        text: 'Proposal Engine',
      },
      {
        href: '/proposal-operations/proposals-engine/',
        text: 'Customers',
      },
      {
        href: `/proposal-operations/proposals-engine/${salesforceCustomerId}`,
        text: customerName,
      },
      {
        href: `/proposal-operations/proposals-engine/${salesforceCustomerId}/opportunities/${opportunityId}${location.search}`,
        text: `Scenario Analysis - ${redaptiveOpportunityId}`,
      },
    ],
    [
      salesforceCustomerId,
      customerName,
      opportunityId,
      location,
      redaptiveOpportunityId,
    ],
  )
  const getColumnHeader = React.useCallback(({ column: { id } }) => {
    if (id === 'opportunityCurrencyCode') {
      return 'Currency'
    }

    if (id === 'createdOnStageName') {
      return 'Stage'
    }

    return scenarioFieldInfo?.[id]?.label || ''
  }, [])
  const getScenarioNameUri = React.useCallback(
    (id: string) => {
      const scenarioQueryParams = {
        scenario: id,
      }
      return `${url}?${queryStringify(scenarioQueryParams)}`
    },
    [queryParams, url],
  )
  const getSeekMarginUri = React.useCallback(
    (id: string) => {
      const seekMarginQueryParams = {
        seekMarginScenarioId: id,
      }
      return `${url}?${queryStringify(seekMarginQueryParams)}`
    },
    [queryParams, url],
  )
  const cellScenarioName = React.useCallback(
    ({
      row: {
        original: { id },
      },
      value,
    }) => (
      <TableStyledLink data-id={id} href={getScenarioNameUri(id)}>
        {value}
      </TableStyledLink>
    ),
    [getScenarioNameUri],
  )
  const handleActivateScenarioClick = React.useCallback(
    (id: string) => () => {
      setActionsPopupId('')
      setArchivedVisible(false)
      setScenarioActivating(true)
      actions.updateProposalScenarioStatus({
        id,
        status: 'APPROVED',
      })
    },
    [],
  )

  /**
   * Method for removing object properties
   */
  const removeObjectProperties = (objct: Record<string, any>, items) => {
    const obj = objct
    items.forEach((item) => {
      delete obj[item]
    })
  }

  const handleCloneScenarioClick = React.useCallback(
    (id: string) => () => {
      setActionsPopupId('')
      setCloneConfirmModalId(id)
    },
    [],
  )
  const handleCloneConfirmModalCancel = React.useCallback(() => {
    setCloneConfirmModalId('')
  }, [cloneConfirmModalId])
  const handleCloneConfirmModalSubmit = React.useCallback(() => {
    const namePrefix = 'New - '

    /**
     * annualSavedKwh is set to undefined to get it recalculated on load,
     * so that form value changes from passed state
     * and Add Scenario button is enabled on load
     */
    const cloneScenario = {
      ...scenariosById[cloneConfirmModalId],
      name: namePrefix + (scenariosById[cloneConfirmModalId]?.name ?? ''),
      status: 'PENDING',
      annualSavedKwh: undefined,
    }
    removeObjectProperties(cloneScenario, [
      'id',
      'modified',
      'modifiedBy',
      'opportunityStatus',
      'salesforceCustomerId',
      'created',
      'createdBy',
    ])
    history.push({
      pathname: `${location.pathname}/add-scenario`,
      state: {
        opportunity: opportunitySummary,
        isClone: true,
        cloneScenario,
      },
    })
    setCloneConfirmModalId('')
  }, [cloneConfirmModalId])
  const handleArchiveScenarioClick = React.useCallback(
    (id: string) => () => {
      setActionsPopupId('')
      setArchiveConfirmModalId(id)
    },
    [],
  )
  const handleArchiveConfirmModalCancel = React.useCallback(() => {
    setArchiveConfirmModalId('')
  }, [archiveConfirmModalId])
  const handleArchiveConfirmModalSubmit = React.useCallback(() => {
    actions.updateProposalScenarioStatus({
      id: archiveConfirmModalId,
      status: 'ARCHIVED',
    })
    setArchiveConfirmModalId('')
  }, [archiveConfirmModalId])
  const handleUpdateSalesforceConfirmModalCancel = React.useCallback(() => {
    setUpdateSalesforceId('')
    setSalesforcePopupId(false)
  }, [updateSalesforceId, salesforcePopupId])
  const handleUpdateSalesforceConfirmModalSubmit = React.useCallback(() => {
    actions.updateProposalOpportunityStatus({
      opportunityId: updateSalesforceId,
    })
    setSalesforcePopupId(false)
    setSalesforceAlertPopup(true)
  }, [salesforcePopupId])
  const handleSetPendingScenarioClick = React.useCallback(
    (id: string) => () => {
      setActionsPopupId('')
      actions.updateProposalScenarioStatus({
        id,
        status: 'PENDING',
      })
    },
    [],
  )
  const handleSeekMargin = React.useCallback(
    (id: string) => () => {
      setActionsPopupId('')
      history.push(getSeekMarginUri(id))
    },
    [],
  )
  const handleEditScenarioClick = React.useCallback(
    (id: string) => () => {
      setActionsPopupId('')
      history.push(`${getScenarioNameUri(id)}&editMode=1`)
    },
    [],
  )
  const handleLockUnlockClick = React.useCallback(() => {
    setActionsPopupId('')
    history.push('?showLockUnlockModal=true')
  }, [])
  const isActiveScenario = React.useMemo(
    () =>
      !!scenarios.some(
        (scenario) =>
          scenario.status === 'APPROVED' &&
          opportunitySummary &&
          scenario.createdOnStageName === opportunitySummary.stageName,
      ),
    [scenarios, opportunitySummary],
  )
  React.useEffect(() => {
    if (isActiveScenario && isProposalOperationsUser) {
      actions.fetchSalesforceScenarioStatus({
        opportunityId,
      })
    }
  }, [])
  const getUpdateSalesforceModalBodyContent: Record<string, any> =
    React.useCallback(() => {
      switch (proposalSalesforceStatus) {
        case 'NEW_CONTRACT':
          return proposalModalContent.NEW_CONTRACT

        case 'UPDATE':
          return proposalModalContent.UPDATE

        case 'REJECT_AND_UPDATE':
          return proposalModalContent.REJECT_AND_UPDATE

        default:
          return proposalModalContent.DEFAULT
      }
    }, [proposalSalesforceStatus])
  const renderArchiveConfirmModalBody = React.useCallback(
    () => (
      <>
        <ConfirmBodyTitleStyled>
          Are you sure you want to archive
        </ConfirmBodyTitleStyled>
        <ConfirmBodyScenarioNameStyled>
          {scenariosById[archiveConfirmModalId].name}?
        </ConfirmBodyScenarioNameStyled>
      </>
    ),
    [archiveConfirmModalId],
  )
  const renderCloneConfirmModalBody = React.useCallback(
    () => (
      <>
        <ConfirmBodyTitleStyled>
          Are you sure you want to clone this scenario?
        </ConfirmBodyTitleStyled>
        <ConfirmBodyContentStyled>
          You will be able to make edits before adding the scenario on the next
          screen.
        </ConfirmBodyContentStyled>
      </>
    ),
    [cloneConfirmModalId],
  )
  const renderUpdateSalesfoceConfirmModalBody = React.useCallback(
    () => (
      <>
        <ConfirmBodyTitleStyled>
          {getUpdateSalesforceModalBodyContent().header}
        </ConfirmBodyTitleStyled>
        <ConfirmBodyContentStyled>
          {getUpdateSalesforceModalBodyContent().body}
        </ConfirmBodyContentStyled>
      </>
    ),
    [proposalSalesforceStatus],
  )
  const getUpdateSalesfoceConfirmModalLogMessage = React.useCallback(() => {
    if (proposalSalesforceStatus !== null) {
      return {
        messageType: 'INFO',
        // $FlowFixMe
        messageBody:
          proposalModalContent[`${proposalSalesforceStatus}`].logMessage,
      }
    }

    return {
      messageType: 'INFO',
      messageBody: '',
    }
  }, [proposalSalesforceStatus])
  const ActionsPopup = React.useMemo(
    () =>
      ({
        id,
        open,
        status,
        createdOnStageName,
      }: {
        id: string
        open: boolean
        status: string
        createdOnStageName: string
      }) => {
        const isEditPermissible =
          createdOnStageName === opportunitySummary?.stageName
        return (
          <ActionsPopupStyled open={open && !isOpportunityInBillingMonitoring}>
            {status === 'PENDING' &&
              isProposalOperationsUser &&
              isEditPermissible && (
                <ActionStyled onClick={handleActivateScenarioClick(id)}>
                  <ActionIconWrapperStyled>
                    <CheckCircleOutlineIcon fontSize='24px' />
                  </ActionIconWrapperStyled>
                  Mark as Active
                </ActionStyled>
              )}
            {['ARCHIVED', 'PENDING'].includes(status) && isEditPermissible && (
              <ActionStyled onClick={handleEditScenarioClick(id)}>
                <ActionIconWrapperStyled>
                  <EditNoteIcon fontSize='24px' />
                </ActionIconWrapperStyled>
                Edit Scenario
              </ActionStyled>
            )}
            {status !== 'APPROVED' && isEditPermissible && (
              <ActionStyled onClick={handleSeekMargin(id)}>
                <ActionIconWrapperStyled>
                  <SeekMarginIcon />
                </ActionIconWrapperStyled>
                Seek Margin
              </ActionStyled>
            )}
            {((status === 'ARCHIVED' && !isActiveScenario) ||
              (status === 'APPROVED' && isProposalOperationsUser)) &&
              isEditPermissible && (
                <ActionStyled onClick={handleSetPendingScenarioClick(id)}>
                  <ActionIconWrapperStyled>
                    <HourglassEmptyIcon fontSize='24px' />
                  </ActionIconWrapperStyled>
                  Mark as Pending
                </ActionStyled>
              )}
            <ActionStyled onClick={handleCloneScenarioClick(id)}>
              <ActionIconWrapperStyled>
                <Layers fontSize='24px' />
              </ActionIconWrapperStyled>
              Clone Scenario
            </ActionStyled>
            {status === 'PENDING' && isEditPermissible && (
              <ArchiveActionStyled onClick={handleArchiveScenarioClick(id)}>
                <ActionIconWrapperStyled>
                  <Inventory2OutlinedIcon fontSize='24px' />
                </ActionIconWrapperStyled>
                Archive Scenario
              </ArchiveActionStyled>
            )}
          </ActionsPopupStyled>
        )
      },
    [isActiveScenario, isLocked],
  )
  const handleKeydown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && actionsPopupId) {
        setActionsPopupId('')
      }
    },
    [actionsPopupId],
  )
  React.useEffect(() => {
    if (actionsPopupId) {
      document.addEventListener('keydown', handleKeydown, true)
    } else {
      document.removeEventListener('keydown', handleKeydown, true)
    }

    return () => {
      document.removeEventListener('keydown', handleKeydown, true)
    }
  }, [actionsPopupId])
  const getActionsCell = React.useCallback(
    (cellProps) => {
      const {
        row: {
          original: { id, status, createdOnStageName },
        },
      } = cellProps

      if (!isProposalOperationsUser) {
        return ''
      }

      const handleActionsIconClick = () => {
        setActionsPopupId(actionsPopupId !== id ? id : '')
      }

      return (
        <ActionsCellWrapperStyled>
          <ActionsIconStyled onClick={handleActionsIconClick}>
            <IonIcon fontSize='24px' iconClass='ion-android-more-vertical' />
          </ActionsIconStyled>
          <ActionsPopup
            id={id}
            getScenarioNameUri={getScenarioNameUri}
            history={history}
            open={actionsPopupId === id}
            status={status}
            createdOnStageName={createdOnStageName}
          />
        </ActionsCellWrapperStyled>
      )
    },
    [actionsPopupId],
  )
  const getColumnCell = React.useCallback(
    (cellProps) => {
      const {
        column: { id },
        value,
        row,
      } = cellProps

      if (id === 'name') {
        return cellScenarioName(cellProps)
      }

      if (id === 'opportunityCurrencyCode') {
        return currencyCode
      }

      if (id === 'modified') {
        return formatDateStringForView(cellProps)
      }

      if (id === 'modifiedBy') {
        return convertEmailToNameForView(cellProps)
      }

      if (id === 'created') {
        return formatDateStringForView(cellProps)
      }

      if (id === 'createdBy') {
        return convertEmailToNameForView(cellProps)
      }

      if (id === 'vendorProposalScenarioFileName') {
        const {
          original: {
            vendorProposalScenarioFileName,
            vendorProposalScenarioId,
          },
        } = row || {}

        if (vendorProposalScenarioFileName && vendorProposalScenarioId) {
          return (
            <DownloadVendorFile
              fileIds={vendorProposalScenarioId}
              name={vendorProposalScenarioFileName}
            />
          )
        }
      }

      const { fieldType = '' } = scenarioFieldInfo[id] || {}

      switch (fieldType) {
        case 'boolean':
          return cellBoolean(cellProps)

        case 'currency':
          return cellCurrency(cellProps)

        case 'enum':
          return cellEnum(cellProps)

        case 'percentage':
          return cellNumber2DigitsPercentage(cellProps)

        case 'string':
          return value || ''

        default:
          return value
      }
    },
    [
      cellScenarioName,
      cellCurrency,
      cellNumber2DigitsPercentage,
      cellBoolean,
      cellEnum,
    ],
  )
  const initialLockedColumns = React.useMemo(
    () =>
      fieldMappings && fieldMappings.length !== 0 ?
        fieldMappings
          .filter((column) => column.locked === true)
          .map((column) => column.fieldName)
      : proposalFieldMappings
          .filter((column) => column.locked === true)
          .map((column) => column.fieldName),
    [fieldMappings, fieldMappingsMeta],
  )
  const initialHiddenColumns = React.useMemo(
    () =>
      fieldMappings && fieldMappings.length !== 0 ?
        fieldMappings
          .filter((column) => column.visible !== true)
          .map((column) => column.fieldName)
      : proposalFieldMappings
          .filter((column) => column.visible !== true)
          .map((column) => column.fieldName),
    [fieldMappings],
  )
  const columnSettings: Record<
    string,
    {
      minWidth: number
    }
  > = React.useMemo(
    () => ({
      name: {
        minWidth: 200,
      },
      createdOnStageName: {
        minWidth: 100,
      },
      contractTermMonths: {
        minWidth: 100,
      },
      dealType: {
        minWidth: 100,
      },
      energyRetainedSavingsInPercentage: {
        minWidth: 120,
      },
      netCost: {
        minWidth: 120,
      },
      maintenanceRetainedSavingsInPercentage: {
        minWidth: 120,
      },
      preTaxContractValue: {
        minWidth: 120,
      },
      estimatedSalesTax: {
        minWidth: 120,
      },
      totalContractValueWithSalesTax: {
        minWidth: 120,
      },
      netMarginInDollars: {
        minWidth: 120,
      },
      netMarginInPercentage: {
        minWidth: 120,
      },
      internalRateOfReturn: {
        minWidth: 120,
      },
      grossMarginInPercentage: {
        minWidth: 120,
      },
      totalProposedFixtureCount: {
        minWidth: 120,
      },
      annualSavedKwh: {
        minWidth: 120,
      },
      opportunityCurrencyCode: {
        minWidth: 120,
      },
      partnerFee: {
        minWidth: 120,
      },
      partnerFeeInPercentage: {
        minWidth: 120,
      },
      referralCost: {
        minWidth: 120,
      },
      referralFeeInPercentage: {
        minWidth: 120,
      },
      contingencyInPercentage: {
        minWidth: 120,
      },
      contingencyCost: {
        minWidth: 120,
      },
      upFrontMeasurementAndVerification: {
        minWidth: 120,
      },
      meterCount: {
        minWidth: 120,
      },
      finalRebateAmount: {
        minWidth: 120,
      },
      rebateHcInPercentage: {
        minWidth: 120,
      },
      annualEnergySavingsInDollars: {
        minWidth: 120,
      },
      annualMaintenanceSavings: {
        minWidth: 120,
      },
      costReduction: {
        minWidth: 120,
      },
      upFrontPayment: {
        minWidth: 120,
      },
      utilityRate: {
        minWidth: 120,
      },
      contractRate: {
        minWidth: 120,
      },
      annualPreKwh: {
        minWidth: 120,
      },
      annualPostKwh: {
        minWidth: 120,
      },
      modified: {
        minWidth: 120,
      },
      modifiedBy: {
        minWidth: 120,
      },
      created: {
        minWidth: 120,
      },
      createdBy: {
        minWidth: 120,
      },
      vendorProposalScenarioFileName: {
        minWidth: 180,
      },
    }),
    [],
  )
  const columns = React.useMemo(() => {
    // If the field mappings are null returns default mappings
    // columns.
    // const visibleFieldNames = fieldMappings.filter(column => column.visible === true);
    const columnsFromFieldMappings =
      fieldMappings?.length ?
        fieldMappings.map((column) => column.fieldName) // ? fieldMappings.filter(column => column.visible === true).map(column => column.fieldName)
      : proposalFieldMappings.map((column) => column.fieldName)
    // Combines columns from the URI with any columns that are in the valid
    // columns but not included in the URI.
    const columnsOrdered =
      columnsFromFieldMappings ?
        [...columnsFromFieldMappings]
      : Object.keys(columnSettings)
    const columnsList = columnsOrdered.map((accessor) => ({
      accessor,
      sortType: reactTableSortArchivedScenariosLast,
      Cell: getColumnCell,
      Header: getColumnHeader,
      minWidth: columnSettings[accessor]?.minWidth || 140,
    }))

    if (!(isOpportunityInInstallation && isLocked)) {
      columnsList.push({
        id: 'actions',
        disableSortBy: true,
        hideSettings: true,
        Cell: getActionsCell,
        minWidth: 50,
      })
    }

    return columnsList
  }, [actionsPopupId, location, fieldMappings, isLocked])

  const onCloseAddScenarioStatusModal = () => {
    clearVendorIngestionData()
  }

  const redirectToAddScenarioPage = (): void => {
    history.push(`${location.pathname}/add-scenario`)
  }
  const redirectToScenarioAnalysisPage = (): void => {
    setupdateSalesforceAlertsStatus('')
    setSalesforcePopupId(false)
    setSalesforceAlertPopup(false)
    history.push(`${location.pathname}`)
  }

  const onFileAdded = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const file = e.currentTarget?.files?.[0]
    const { name } = file
    const extension = name.split('.').pop()
    const validExtensions = ['xlsx', 'xlsm']
    const validFileNameLength = 200

    e.target.value = ''

    const isValid = () => {
      if (!validExtensions.includes(extension)) {
        setAddScenarioAlertStatus(ADD_SCENARIO_STATES.DANGER)
        return false
      }
      if (name.length > validFileNameLength) {
        setAddScenarioAlertStatus(ADD_SCENARIO_STATES.FILE_NAME_LENGTH)
        return false
      }
      return true
    }

    if (isValid()) {
      // HIT API CALL
      setUploadedFileName(name)
      setVendorIngestedFile(file)
      actions.requestImportedScenarioFields({
        file,
        name: opportunitySummary?.name,
        opportunityId,
      })
      setAddScenarioAlertStatus(ADD_SCENARIO_STATES.LOADING)
    }
  }

  const AddScenarioModal = addScenarioAlertStatus && (
    <ModalAlerts
      text={uploadedFileName} // eslint-disable-next-line react/jsx-props-no-spreading
      {...addScenarioAlerts[addScenarioAlertStatus]}
      onClose={onCloseAddScenarioStatusModal}
      onSuccess={redirectToAddScenarioPage}
      onUpload={onFileAdded}
      fileParsingError={importedScenarioData.fileParsingError}
      showFooter={addScenarioAlertStatus !== ADD_SCENARIO_STATES.LOADING}
    />
  )
  const UpdateSalesforceModal = updateSalesforceAlertsStatus && (
    <UpdateSalesforceAlerts
      text={redaptiveOpportunityId} // eslint-disable-next-line react/jsx-props-no-spreading
      {...updateSalesforceAlerts[updateSalesforceAlertsStatus]}
      onClose={redirectToScenarioAnalysisPage}
      onSuccess={redirectToScenarioAnalysisPage}
      showFooter={
        updateSalesforceAlertsStatus !== UPDATE_SALESFORCE_STATES.LOADING
      }
    />
  )
  const HeaderActions = React.useCallback(
    () => (
      <>
        {opportunitySummary &&
          !!opportunitySummary.opportunityStatus &&
          ((isOpportunityInInstallation && !opportunitySummary?.isLocked) ||
            !isOpportunityInInstallation) && (
            <FeatureValidator
              feature={AuthorizedFeatures.createProposalScenario}
            >
              <AddScenarioDropdown
                onFileAdded={onFileAdded}
                onRedirect={redirectToAddScenarioPage}
                disabled={isOpportunityInBillingMonitoring}
              />
            </FeatureValidator>
          )}
      </>
    ),
    [location, opportunitySummary],
  )
  const getRowProps = React.useCallback(
    (row: Record<string, any>) => {
      const {
        original: { status, createdOnStageName },
      } = row
      const scenarioClassName =
        createdOnStageName !== opportunitySummary?.stageName ?
          'old-scenario'
        : ''
      const fields: Record<string, any> = {
        className: `row-status-${status.toLowerCase()} ${scenarioClassName}`,
      }

      if (status === 'APPROVED' || status === 'SCOPING_APPROVED') {
        fields.highlightColor = colors.green
      }

      return fields
    },
    [opportunitySummary],
  )
  const handleArchivedToggleClick = React.useCallback(() => {
    setArchivedVisible(!archivedVisible)
  }, [archivedVisible])
  const archivedCount = React.useMemo(
    () =>
      scenarios.reduce(
        (acc, cur) => (cur.status === 'ARCHIVED' ? acc + 1 : acc),
        0,
      ),
    [scenarios],
  )

  const enhancedScenarioTableData = (
    scenarioData: FTProposalScenarioResponse[],
  ) =>
    scenarioData.map((value) => {
      if (value.estimatedSalesTaxInPercentage === salesTaxWarningValue) {
        return { ...value, estimatedSalesTax: 'Not Calculated' }
      }

      return {
        ...value,
        estimatedSalesTax: currencyFormat.format(value.estimatedSalesTax),
      }
    })

  const sortedScenarios = React.useMemo(() => {
    if (scenarios.length) {
      let enhancedScenarios = enhancedScenarioTableData([...scenarios] || [])
      enhancedScenarios = enhancedScenarios.map((scenario) => ({
        ...scenario,
        monthlyPreBurnHours: getScenarioCalculations(
          scenario,
          site,
        ).monthlyPreBurnHours(scenario, site),
        monthlyPostBurnHours: getScenarioCalculations(
          scenario,
          site,
        ).monthlyPostBurnHours(scenario, site),
      }))
      return enhancedScenarios.sort(sortScenarios) || []
    }

    return scenarios
  }, [scenarios, currencyCode, locale])
  const handleDownloadSummaryClick = React.useCallback(() => {
    actions.downloadProposalOpportunitySummary({
      filename: `${redaptiveOpportunityId} - Summary documents - ${moment().format(
        DATE_FORMAT_DATA_API_RESPONSE,
      )}.zip`,
      opportunityId,
    })
  }, [opportunityId, redaptiveOpportunityId])
  const handleUpdateSalesforceClick = React.useCallback(() => {
    actions.fetchSalesforceScenarioStatus({
      opportunityId,
    })
    setUpdateSalesforceId(opportunityId)
    setSalesforcePopupId(true)
  }, [opportunityId, redaptiveOpportunityId])

  const copyToClipboard = (sel) => {
    document.execCommand('copy')
    sel.removeAllRanges()
  }

  const handleColumnSettingsClose = React.useCallback(
    ({ columns: columnsNew, lockedColumns, columnsVisibleOnTable }) => {
      const fieldMappingsNew = columnsNew.map((columnEntry, index) => ({
        fieldName: columnEntry,
        locked: lockedColumns.includes(columnEntry),
        visible: columnsVisibleOnTable.includes(columnEntry),
        sequenceNumber: index,
      }))
      setUpdatedFieldMappings(fieldMappingsNew)
      actions.updateProposalOpportunityFieldMappings({
        opportunityId,
        fieldMappings: fieldMappingsNew,
      })
    },
    [opportunityId],
  )

  const getVisibleColumnHeaders = (mappings) =>
    mappings
      .filter((field) => field.visible)
      .map((field) => {
        if (field.fieldName === 'opportunityCurrencyCode') {
          return '<th>Currency</th>'
        }

        return `<th>${scenarioFieldInfo?.[field.fieldName]?.label}</th>`
      })
      .join('')

  const getVisibleTableRowData = (scenario) =>
    updatedFieldMappings
      .filter((field) => field.visible)
      .map((field) => {
        const name = field.fieldName

        if (name === 'opportunityCurrencyCode') {
          return `<td>${currencyCode}</td>`
        }

        if (currencySet.has(name)) {
          return `<td>
          ${formatScenarioFieldValueCurrency({
            locale,
            currencyCode,
            value: scenario[name],
            precision: scenarioFieldInfo[name].precision,
          })}
        </td>`
        }

        if (percentageSet.has(name)) {
          return `<td>
          ${formatScenarioFieldValuePercentage({
            locale,
            value: scenario[name],
          })}
        </td>`
        }

        if (name === 'created') {
          return `<td>
          ${formatDateStringForView({
            value: scenario[name],
          })}
        </td>`
        }

        if (name === 'createdBy') {
          return `<td>
          ${convertEmailToNameForView({
            value: scenario[name],
          })}
        </td>`
        }

        if (name === 'modified') {
          return `<td>
          ${formatDateStringForView({
            value: scenario[name],
          })}
        </td>`
        }

        if (name === 'modifiedBy') {
          return `<td>
          ${convertEmailToNameForView({
            value: scenario[name],
          })}
        </td>`
        }

        return `<td>${scenario[name]}</td>`
      })
      .join('')

  const getVisibleTableData = (mappings) => {
    let copyScenarios = mappings

    if (copyScenarios.length && isActiveScenario) {
      copyScenarios = copyScenarios.filter((obj) => obj.status === 'APPROVED')
    } else if (copyScenarios.length && !archivedVisible) {
      copyScenarios = copyScenarios.filter((obj) => obj.status !== 'ARCHIVED')
    }

    return copyScenarios
      .map(
        (scenario) =>
          `<tr align="center">${getVisibleTableRowData(scenario)}</tr>`,
      )
      .join('')
  }

  // const showClipboard = () => {
  //   navigator.clipboard.readText().then(text => {
  //     console.log('Copied content: ', text);
  //   });
  // };
  const copyTable = () => {
    // $FlowFixMe
    const elTable = document.querySelector('.table-wrapper table')
    const tempTableNode = document.createElement('div')

    if (elTable) {
      elTable.appendChild(tempTableNode)
    }

    tempTableNode.id = 'temp-copy-table'
    const tempTableElement = document.querySelector(`#${tempTableNode.id}`)

    if (document.createRange && window.getSelection) {
      // Ensure that range and selection are supported by the browsers
      const range = document.createRange()
      const sel = window.getSelection()

      try {
        if (tempTableElement) {
          tempTableElement.innerHTML =
            '<table border="1" cellspacing="0" cellpadding="10" align="center" nowrap>' +
            '<tr align="center">' +
            `${getVisibleColumnHeaders(updatedFieldMappings)}` +
            '</tr>' +
            `${getVisibleTableData(sortedScenarios)}` +
            '</table>'
          range.selectNodeContents(tempTableElement)
          sel.addRange(range)
        }
      } catch (e) {
        if (tempTableElement) {
          range.selectNode(tempTableElement)
          sel.addRange(range)
        }
      }

      copyToClipboard(sel)

      /** Adding again to copy because copy was not working on first click!
       * If found any better solution, then can call copy only once!
       */
      sel.addRange(range)
      copyToClipboard(sel)
    }

    // showClipboard();
    actions.showMessage({
      messageId: 'copyTableConfirmation',
      title: 'Table Copied!',
      type: 'success',
    })
    setTimeout(() => {
      actions.hideMessage('copyTableConfirmation')
      // $FlowFixMe
      document.querySelector('#temp-copy-table')?.remove()
    }, 100)
  }

  const getCsvData = () => {
    const json2csvParser = new Parser({
      proposalReviewCSVMappings,
    })
    const activeScenearioData = sortedScenarios.filter(
      (scenario) => scenario.status === 'APPROVED',
    )
    const scenario = activeScenearioData[0]
    const csvDataMapping = {
      'Scenario ID': scenario.name,
      ROID: redaptiveOpportunityId,
      'Address including zip code': `${site.shippingStreet} ${site.shippingCity} ${site.shippingState}, ${site.shippingPostalCode}`,
      'Sq. Feet': site.squareFeet,
      'Total Proposed Fixture Count': numberFormatMaxFractionDigits2.format(
        scenario.totalProposedFixtureCount,
      ),
      'kWh Savings': numberFormatMaxFractionDigits2.format(
        scenario.annualSavedKwh,
      ),
      'Block kWh': numberFormatMaxFractionDigits2.format(
        scenario.annualSavedKwh * (scenario.contractTermMonths / 12),
      ),
      'kWh Savings  %': formatScenarioFieldValuePercentage({
        locale,
        value: numberFormatMaxFractionDigits2.format(
          (scenario.annualSavedKwh / scenario.annualPreKwh) * 100,
        ),
      }),
      'Pre Burn Hour': numberFormatMaxFractionDigits2.format(
        scenario.annualPreBurnHours,
      ),
      'Pre Burn Hour - Monthly': numberFormatMaxFractionDigits2.format(
        scenario.annualPreBurnHours / 12,
      ),
      'Post Burn Hour': numberFormatMaxFractionDigits2.format(
        scenario.annualPostBurnHours,
      ),
      'Post Burn Hour - Monthly': numberFormatMaxFractionDigits2.format(
        scenario.annualPostBurnHours / 12,
      ),
      'Utility Rate': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.utilityRate,
        precision: 4,
      }),
      'Contract Rate': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.contractRate,
        precision: scenarioFieldInfo.contractRate.precision,
      }),
      'Maintenance Contract Value': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.maintenanceContractValue,
        precision: scenarioFieldInfo.maintenanceContractValue.precision,
      }),
      'Monthly Maintenance Contract Value': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.maintenanceContractValue / scenario.contractTermMonths,
        precision: scenarioFieldInfo.maintenanceContractValue.precision,
      }),
      'Final Rebate Amount': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.finalRebateAmount,
        precision: scenarioFieldInfo.finalRebateAmount.precision,
      }),
      'Total Energy Savings': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.annualEnergySavingsInDollars,
        precision: scenarioFieldInfo.annualEnergySavingsInDollars.precision,
      }),
      'Total Soft Savings': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.annualMaintenanceSavings,
        precision: scenarioFieldInfo.annualMaintenanceSavings.precision,
      }),
      'Post Term Savings': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value:
          scenario.annualEnergySavingsInDollars +
          scenario.annualMaintenanceSavings,
        precision: scenarioFieldInfo.annualEnergySavingsInDollars.precision,
      }),
      'Annual Payment to Redaptive': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value:
          scenario.preTaxContractValue / (scenario.contractTermMonths / 12),
        precision: scenarioFieldInfo.preTaxContractValue.precision,
      }),
      'Estimated Annual Net Savings': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value:
          scenario.annualEnergySavingsInDollars +
          scenario.annualMaintenanceSavings -
          scenario.preTaxContractValue / (scenario.contractTermMonths / 12),
        precision: scenarioFieldInfo.annualEnergySavingsInDollars.precision,
      }),
      'Pre-tax Contract Value': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.preTaxContractValue,
        precision: scenarioFieldInfo.preTaxContractValue.precision,
      }),
      'Est Sales Tax': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.estimatedSalesTax,
        precision: scenarioFieldInfo.estimatedSalesTax.precision,
      }),
      'Total Contract Value': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: scenario.preTaxContractValue,
        precision: scenarioFieldInfo.preTaxContractValue.precision,
      }),
      'Term Length': scenario.contractTermMonths / 12,
      'Term Length - Months': scenario.contractTermMonths,
      '10 Year Savings': formatScenarioFieldValueCurrency({
        locale,
        currencyCode,
        value: Big(
          scenario.annualEnergySavingsInDollars +
            scenario.annualMaintenanceSavings -
            scenario.preTaxContractValue / (scenario.contractTermMonths / 12),
        ).times(10),
        precision: scenarioFieldInfo.annualEnergySavingsInDollars.precision,
      }),
      'Retained Maintenance Savings': formatScenarioFieldValuePercentage({
        locale,
        value: numberFormatMaxFractionDigits2.format(
          scenario.maintenanceRetainedSavingsInPercentage,
        ),
      }),
      'Pre-Install Consumption': numberFormatMaxFractionDigits2.format(
        scenario.annualPreKwh,
      ),
      'Pre-Install Consumption - Monthly':
        numberFormatMaxFractionDigits2.format(scenario.annualPreKwh / 12),
      'Post-Install Consumption': numberFormatMaxFractionDigits2.format(
        scenario.annualPostKwh,
      ),
      'Post-Install Consumption - Monthly':
        numberFormatMaxFractionDigits2.format(scenario.annualPostKwh / 12),
      'Retained Energy Savings': formatScenarioFieldValuePercentage({
        locale,
        value: numberFormatMaxFractionDigits2.format(
          scenario.energyRetainedSavingsInPercentage,
        ),
      }),
      'Modelled Currency': currencyCode,
      'Currency Symbol': getSymbolFromCurrency(currencyCode),
      'Estimated Metric Tons of CO2e Avoided Annually':
        scenario.metricTonsOfCo2AvoidedAnnually,
      'Estimated Metric Tons of CO2e Avoided over Contract Term':
        scenario.metricTonsOfCo2AvoidedContractTerm,
      'Estimated Metric Tons of CO2e Avoided over EUL':
        scenario.metricTonsOfCo2AvoidedOverEul,
      'Additional Retained Savings from UBR Hedging over Contract Term':
        formatScenarioFieldValueCurrency({
          locale,
          currencyCode,
          value: scenario.savingsThroughUbrHedging,
          precision: scenarioFieldInfo.savingsThroughUbrHedging.precision,
        }),
      'Additional Savings from Avoided REC Purchase over EUL':
        formatScenarioFieldValueCurrency({
          locale,
          currencyCode,
          value: scenario.savedDollarsOnAvoidedRecPurchase,
          precision:
            scenarioFieldInfo.savedDollarsOnAvoidedRecPurchase.precision,
        }),
    }
    const csvData = proposalReviewMappings.map((field) => ({
      'Field Name': field.fieldName,
      Value: csvDataMapping[field.fieldName],
    }))
    return json2csvParser.parse(csvData)
  }

  const handleDownloadReviewClick = () => {
    const file = new Blob([getCsvData()], {
      type: 'text/csv',
    })
    const fileName = `${redaptiveOpportunityId} - Review Page - ${moment().format(
      DATE_FORMAT_DATA_API_RESPONSE,
    )}`
    FileSaver.saveAs(file, fileName)
  }

  const getDownloadMenu = () => {
    const validateActions = []
    validateActions.push({
      label: 'Summary',
      onClick: handleDownloadSummaryClick,
      icon: <TbPdf size={20} />,
    })
    validateActions.push({
      label: 'Review Page',
      onClick: handleDownloadReviewClick,
      icon: <TbCsv size={20} />,
    })
    return (
      <DownloadDropDownWrapper>
        <DropdownMenuNew
          title='Download'
          actions={validateActions}
          bgColor={colors.gray4}
          iconBefore
          borderOnClose={false}
        />
      </DownloadDropDownWrapper>
    )
  }

  return (
    <>
      <OpportunityAnalysisPageStyles archivedVisible={archivedVisible}>
        {customerSummariesLoading && (
          <SpinnerStyles>
            <Spinner />
          </SpinnerStyles>
        )}
        {!!customerName && !!opportunitySummary && (
          <>
            <BreadcrumbsStyles>
              <Breadcrumbs2 items={breadcrumbs} />
            </BreadcrumbsStyles>
            <HeaderWrapperStyles>
              <TitleWrapperStyled>
                <ArrowBackIcon onClick={navigateBack}>Back</ArrowBackIcon>
                <TitleStyles>
                  {'Scenario Analysis - \u00a0'}
                  <b>{`${redaptiveOpportunityId} `}</b>
                </TitleStyles>
                &nbsp;
                {isOpportunityInInstallation &&
                  (isLocked ? <LockIconOutlined /> : <LockOpenIcon />)}
                <OpportunityStatus status={opportunityStatus} />
              </TitleWrapperStyled>
              <HeaderActionsStyled>
                {!!scenarios.length && (
                  <HeaderActionStyled
                    onClick={() => {
                      copyTable()
                    }}
                  >
                    <HeaderActionBodyStyled
                      $loading={opportunitySummaryDownloadMeta.loading}
                    >
                      <CopyIconStyled />
                      <span>Copy Table</span>
                    </HeaderActionBodyStyled>
                  </HeaderActionStyled>
                )}
                {isActiveScenario &&
                  ((isOpportunityInInstallation && !isLocked) ||
                    !isOpportunityInInstallation) &&
                  isProposalOperationsUser &&
                  !isOpportunityInBillingMonitoring && (
                    <HeaderActionStyled onClick={handleUpdateSalesforceClick}>
                      <HeaderActionBodyStyled
                        $loading={opportunityStatusUpdateMeta.loading}
                      >
                        <AutorenewIconIconStyled />
                        Update Salesforce
                      </HeaderActionBodyStyled>
                      {opportunityStatusUpdateMeta.loading && (
                        <DownloadSummaryActionSpinnerStyled>
                          <Spinner inline size='tiny' />
                        </DownloadSummaryActionSpinnerStyled>
                      )}
                    </HeaderActionStyled>
                  )}
                {isOpportunityInInstallation && isProposalOperationsAdmin && (
                  <HeaderActionStyled onClick={handleLockUnlockClick}>
                    <HeaderActionBodyStyled $loading={false}>
                      {isLocked ?
                        <>
                          <LockOpenIconStyled />
                          Unlock For Remodeling
                        </>
                      : <>
                          <LockIconOutlinedStyled />
                          Lock For Remodeling
                        </>
                      }
                    </HeaderActionBodyStyled>
                  </HeaderActionStyled>
                )}
                {isActiveScenario && getDownloadMenu()}
              </HeaderActionsStyled>
            </HeaderWrapperStyles>
          </>
        )}
        {scenariosLoading && (
          <SpinnerStyles>
            <Spinner />
          </SpinnerStyles>
        )}
        {!scenariosLoading && opportunitySummary?.id && (
          <TableWrapperStyled className='table-wrapper'>
            <RedaptiveReactTable7
              alwaysLockedColumns={alwaysLockedColumns}
              columns={columns}
              data={sortedScenarios}
              defaultSort={defaultSort}
              enableColumnSettings={showColumnSettings}
              enableColumnHiding
              getRowProps={getRowProps}
              initialLockedColumns={initialLockedColumns}
              initialHiddenColumns={initialHiddenColumns}
              handleColumnSettingsClose={handleColumnSettingsClose}
              HeaderActions={HeaderActions}
            />
            {!!archivedCount && (
              <ArchiveToggleStyled
                archivedVisible={archivedVisible}
                onClick={handleArchivedToggleClick}
              >
                {archivedVisible ?
                  <>
                    <ArchiveToggleIconStyled>
                      <KeyboardDoubleArrowUpIcon />
                    </ArchiveToggleIconStyled>
                    {`Hide ${archivedCount} Archived Scenario${
                      archivedCount > 1 ? 's' : ''
                    }`}
                  </>
                : <>
                    <ArchiveToggleIconStyled>
                      <KeyboardDoubleArrowDownIcon />
                    </ArchiveToggleIconStyled>
                    {`Show ${archivedCount} Archived Scenario${
                      archivedCount > 1 ? 's' : ''
                    }`}
                  </>
                }
              </ArchiveToggleStyled>
            )}
          </TableWrapperStyled>
        )}
      </OpportunityAnalysisPageStyles>
      {!!archiveConfirmModalId && (
        <ArchiveConfirmModalWrapperStyled>
          <ConfirmModal
            handleConfirm={handleArchiveConfirmModalSubmit}
            handleCancel={handleArchiveConfirmModalCancel}
            cancelText='Cancel'
            confirmText='Confirm'
            renderBody={renderArchiveConfirmModalBody}
          />
        </ArchiveConfirmModalWrapperStyled>
      )}
      {AddScenarioModal}
      {!!cloneConfirmModalId && (
        <ConfirmModal
          handleConfirm={handleCloneConfirmModalSubmit}
          handleCancel={handleCloneConfirmModalCancel}
          cancelText='Cancel'
          confirmText='Confirm'
          renderBody={renderCloneConfirmModalBody}
        />
      )}
      {salesforcePopupId && proposalSalesforceStatus !== '' && (
        <ConfirmModal
          handleConfirm={handleUpdateSalesforceConfirmModalSubmit}
          handleCancel={handleUpdateSalesforceConfirmModalCancel}
          logMessageText={
            getUpdateSalesfoceConfirmModalLogMessage().messageBody
          }
          messageType={getUpdateSalesfoceConfirmModalLogMessage().messageType}
          cancelText='Cancel'
          confirmText='Yes, Continue'
          renderBody={renderUpdateSalesfoceConfirmModalBody}
        />
      )}
      {salesforceAlertPopup && UpdateSalesforceModal}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(customerSummariesActions, dispatch),
    ...bindActionCreators(customerGlobalInputsActions, dispatch),
    ...bindActionCreators(modalActions, dispatch),
    ...bindActionCreators(opportunitySummariesActions, dispatch),
    ...bindActionCreators(opportunitySummaryDownloadActions, dispatch),
    ...bindActionCreators(scenarioActions, dispatch),
    ...bindActionCreators(siteActions, dispatch),
    ...bindActionCreators(messagesActions, dispatch),
    ...bindActionCreators(importedScenarioActions, dispatch),
  },
})

const mapStateToProps = (state) => {
  const customerSummariesEntity = selectProposalCustomerSummariesEntity(state)
  const customerGlobalInputsEntity =
    selectProposalCustomerGlobalInputsEntity(state)
  const opportunitySummariesEntity =
    selectProposalOpportunitySummariesEntity(state)
  const opportunitySummaryDownloadMeta =
    selectProposalOpportunitySummaryDownloadMeta(state)
  const scenariosEntity = selectProposalScenarioEntity(state)
  const siteEntity = selectProposalSiteEntity(state)
  const authGroups = authSelectors.selectGroups(state)
  const importedScenario = selectProposalImportedScenario(state)
  const { items: customerGlobalInputs } = customerGlobalInputsEntity
  const {
    meta: { loading: customerSummariesLoading },
    byId: customerSummariesById,
  } = customerSummariesEntity
  const {
    meta: { loading: opportunitySummariesLoading },
    items: opportunitySummaries,
    statusUpdateMeta: opportunityStatusUpdateMeta,
    fieldMappings,
    fieldMappingsMeta,
    proposalSalesforceStatus,
    proposalSalesforceStatusMeta,
  } = opportunitySummariesEntity
  const {
    byId: scenariosById,
    meta: { loading: scenariosLoading },
    items: scenarios,
  } = scenariosEntity
  const { items: sites } = siteEntity
  const {
    auth: { permissions },
  } = state
  const {
    loading: isImportedScenarioLoading,
    data: importedScenarioData,
    file: importedFile,
  } = importedScenario
  return {
    authGroups,
    customerGlobalInputs: customerGlobalInputs[0] || {},
    customerSummariesById,
    customerSummariesLoading,
    opportunityStatusUpdateMeta,
    opportunitySummaries,
    opportunitySummaryDownloadMeta,
    fieldMappings,
    fieldMappingsMeta,
    proposalSalesforceStatus,
    proposalSalesforceStatusMeta,
    scenarios,
    scenariosById,
    scenariosLoading,
    sites,
    permissions,
    importedScenarioData,
    isImportedScenarioLoading,
    importedFile,
    opportunitySummariesLoading,
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OpportunityAnalysisPage),
)
