import { ErrorBoundary } from '@rollbar/react'
import { ConnectedRouter } from 'connected-react-router'
import 'core-js/stable'
import { createBrowserHistory } from 'history'
import 'material-icons/iconfont/material-icons.css'
import 'react-calendar/dist/Calendar.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import PortalContainer from './components/PortalContainer'
import RollbarProvider from './components/RollbarProvider'
import './index.css'
import './init'
import App from './pages/App'
import ErrorPage from './pages/ErrorPage'
import configureStore from './store/configureStore'

const history = createBrowserHistory()
export const store = configureStore({}, history)
const root = document.getElementById('root')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default caching to 20 seconds
      staleTime: 1000 * 20,
    },
  },
})

if (root instanceof Element) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <RollbarProvider>
            <ErrorBoundary fallbackUI={ErrorPage}>
              <App />
              <PortalContainer />
            </ErrorBoundary>
          </RollbarProvider>
        </QueryClientProvider>
      </ConnectedRouter>
    </Provider>,
    root,
  )
}
